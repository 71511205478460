import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  ChecklistId,
  ChecklistInput,
  ChecklistOutput,
  UseCreateChecklist,
  UseDeleteChecklist,
  UseGetChecklists,
  UseGetChecklist,
  UseUpdateChecklist,
  UseUpdateChecklistEntry,
  ChecklistEntryId,
  ChecklistEntryOutput,
  ChecklistEntryUpdateInput,
  UseGetChecklistsBasic,
} from './types/checklist'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'checklists'

function useGetChecklists(): UseGetChecklists {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetChecklist['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetChecklistBasic(): UseGetChecklistsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useGetChecklist(): UseGetChecklist {
  const axios = useAxios<PromiseType<ReturnType<UseGetChecklist['getChecklist']>>>({ method: 'GET' })

  function getChecklist(id: ChecklistId): Promise<ChecklistOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getChecklist,
  }
}

function useCreateChecklist(): UseCreateChecklist {
  const axios = useAxios<PromiseType<ReturnType<UseCreateChecklist['createChecklist']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createChecklist(data: ChecklistInput): Promise<ChecklistOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createChecklist,
  }
}

function useUpdateChecklist(): UseUpdateChecklist {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateChecklist['updateChecklist']>>>({
    method: 'PUT',
  })

  function updateChecklist(id: ChecklistId, data: ChecklistInput): Promise<ChecklistOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateChecklist,
  }
}

function useUpdateChecklistEntry(): UseUpdateChecklistEntry {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateChecklistEntry['updateChecklistEntry']>>>({
    method: 'PUT',
  })

  function updateChecklistEntry(id: ChecklistEntryId, data: ChecklistEntryUpdateInput): Promise<ChecklistEntryOutput> {
    return axios.exec({ url: `/${VERSION}/checklist-entries/${id}`, data })
  }

  return {
    ...axios,
    updateChecklistEntry,
  }
}

function useDeleteChecklist(): UseDeleteChecklist {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteChecklist['deleteChecklist']>>>({
    method: 'DELETE',
  })

  function deleteChecklist(id: ChecklistId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteChecklist,
  }
}

export {
  useGetChecklists,
  useGetChecklistBasic,
  useGetChecklist,
  useCreateChecklist,
  useUpdateChecklist,
  useDeleteChecklist,
  useUpdateChecklistEntry,
}
