import { PspAccountBasicEntity } from '@/api/types/pspAccount'

enum BOARDING_TYPE {
  'ONBOARDING' = 'onboarding',
  'OFFBOARDING' = 'offboarding',
}

enum CHECKLIST_ENTRY_STATUS {
  'OPEN' = 'OPEN',
  'CLOSED' = 'CLOSED',
  'NOT_RELEVANT' = 'NOT_RELEVANT',
}

enum ENTITY_NAME {
  'TASK' = 'task',
  'TASK_CHILD' = 'parent',
  'EQUIPMENT' = 'equipment',
}

enum RELOAD_TYPE {
  PERSON = 'person',
  ASSIGNMENTS = 'assignments',
}

type BudgetDataLeft = {
  budget: string | null
  dailyWorkingTime: number
  hourlyRate: string | null
  banfNr: string
  banfOrderNr: string
  corporateIdentifier: string | undefined
}

type BudgetDataRight = {
  professionalUnit: string | undefined
  bst: string | undefined
  rkost: string | undefined
  pspAccounts: PspAccountBasicEntity[]
}

export { BOARDING_TYPE, CHECKLIST_ENTRY_STATUS, ENTITY_NAME, RELOAD_TYPE, BudgetDataLeft, BudgetDataRight }
