
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiFilter, mdiChevronUp, mdiChevronDown, mdiNotebookEditOutline } from '@mdi/js'
import { isEqual } from 'lodash-es'

import { useFilter } from '@/composables/useFilter'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetChecklists, useUpdateChecklistEntry } from '@/api/checklist'
import { useGetPerson, useGetPersonsBasic } from '@/api/person'
import { useGetChecklistTemplates } from '@/api/checklistTemplate'

import { ChecklistEntryOutput, ChecklistOutput } from '@/api/types/checklist'
import { Rights } from '@/api/types/right'

import { BasicEntity } from '@/api/types/misc'
import { CHECKLIST_ENTRY_STATUS } from '../personProfile/types'
import { FILTER_FIELD_KEY } from '@/views/persons/views/personChecklists/types'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetSuppliersBasic } from '@/api/supplier'

export default defineComponent({
  name: 'PersonChecklists',
  components: {
    PersonChecklistsFilterBar: () =>
      import('@/views/persons/views/personChecklists/components/PersonChecklistsFilterBar.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
  },
  setup: (_, { root }) => {
    const {
      exec: getChecklists,
      data: checklists,
      isLoading: isLoadingChecklists,
      paginationResponse,
    } = useGetChecklists()
    const { exec: getChecklistTemplates, data: checklistTemplates } = useGetChecklistTemplates()

    const activeTab = ref(checklistTemplates.value[0]?.id)

    const initialTab = computed(() => checklistTemplates.value[0]?.id)

    const activePersonId = ref<number | null>(null)

    const totalChecklistsCount = computed(() => paginationResponse.value.totalElements ?? 0)

    const ENTRY_HEADERS = computed(() => {
      let templateId: number

      if (activeTab.value) {
        templateId = activeTab.value
      } else {
        templateId = initialTab.value
      }
      return (
        checklistTemplates.value
          .find((template) => template.id === templateId)
          ?.checklistTaskSet.map((entry) => ({
            text: entry.title,
            value: entry.title.replaceAll(' ', ''),
            sortable: false,
            class: 'header',
          }))
          .flat()
          .sort((a, b) => (a.text > b.text ? 1 : -1)) ?? []
      )
    })

    const TABLE_HEADERS = computed(() => [
      {
        text: root.$t('persons.checklists.table.col.title.id'),
        value: 'person.id',
        sortable: false,
      },
      {
        text: root.$t('persons.checklists.table.col.title.person'),
        value: 'person.name',
        sortable: false,
        width: 500,
      },
      {
        text: root.$t('persons.checklists.table.col.title.contractStartEnd'),
        value: 'contractStart',
        sortable: false,
      },
      {
        text: root.$t('persons.checklists.table.col.title.supplier'),
        value: 'supplier.name',
        sortable: false,
      },
      {
        text: root.$t('persons.checklists.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('persons.checklists.table.col.title.activeProfessionalUnits'),
        value: 'activeProfessionalUnits',
        sortable: false,
      },
      {
        text: root.$t('persons.checklists.table.col.title.countNotes'),
        value: 'countNotes',
        sortable: false,
      },
      ...ENTRY_HEADERS.value,
    ])

    const { updateChecklistEntry } = useUpdateChecklistEntry()

    async function onChangeChecklistTaskStatus(checklistEntry: ChecklistEntryOutput): Promise<void> {
      const currentStatus = checklistEntry.checklistEntryStatus

      let newStatus
      switch (currentStatus) {
        case CHECKLIST_ENTRY_STATUS.OPEN:
          newStatus = CHECKLIST_ENTRY_STATUS.CLOSED
          break
        case CHECKLIST_ENTRY_STATUS.CLOSED:
          newStatus = CHECKLIST_ENTRY_STATUS.NOT_RELEVANT
          break
        case CHECKLIST_ENTRY_STATUS.NOT_RELEVANT:
          newStatus = CHECKLIST_ENTRY_STATUS.OPEN
          break
        default:
          newStatus = CHECKLIST_ENTRY_STATUS.OPEN
          break
      }

      try {
        await updateChecklistEntry(checklistEntry.id, {
          id: checklistEntry.id,
          checklistEntryStatus: newStatus,
        })
      } catch (error: unknown) {
        handleError(error)
      }
      debouncedCb.value()
    }

    const { exec: getPersonsBasic, data: personsBasic, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    getPersonsBasic()

    const {
      exec: getProfUnitsBasic,
      data: profUnitsBasic,
      isLoading: isLoadingProfUnitsBasic,
    } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnitsBasic, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()

    const {
      exec: getSuppliersBasic,
      data: suppliersBasic,
      isLoading: isLoadingSuppliersBasic,
    } = useGetSuppliersBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!profUnitsBasic.value?.length && !orgUnitsBasic.value?.length && !suppliersBasic.value?.length) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getSuppliersBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.PersonIds,
        value: null,
        label: root.$t('persons.checklists.filters.person'),
        items: computed(() => personsBasic.value ?? []),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('persons.filters.profUnit'),
        items: computed(() => profUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('persons.filters.profUnit'),
        items: computed(() => profUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('persons.filters.orgUnit'),
        items: computed(() => orgUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('persons.filters.orgUnit'),
        items: computed(() => orgUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('persons.filters.supplier'),
        items: computed(() => suppliersBasic.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ContractStartFrom,
        value: null,
        label: root.$t('persons.checklists.filters.contractStartFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractStartTo,
        value: null,
        label: root.$t('persons.checklists.filters.contractStartTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractEndFrom,
        value: null,
        label: root.$t('persons.checklists.filters.contractEndFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractEndTo,
        value: null,
        label: root.$t('persons.checklists.filters.contractEndTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.IncludeClosed,
        value: null,
        label: root.$t('persons.checklists.filters.includeClosed'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'id', desc: false })

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getChecklistTemplates({ params: { sort: 'id,asc', size: 9999 } })

      await getChecklists({
        params: {
          ...paginationParams.value,
          ...filterFieldsObject,
          checkListTemplateIds: activeTab.value ?? initialTab.value,
          sort: 'createdAt,desc',
        },
      })

      checklists.value.map((template) =>
        template.checklistEntries.sort((a, b) => (a.checklistTask.name > b.checklistTask.name ? 1 : -1))
      )
    }

    function hasStatus(
      statusToCheck: CHECKLIST_ENTRY_STATUS,
      checklistEntries: ChecklistEntryOutput[],
      checklistTaskNameToFind: BasicEntity['name']
    ): boolean {
      const checklistTaskToCheck = checklistEntries.find(
        ({ checklistTask }) => checklistTask.name === checklistTaskNameToFind
      )
      return checklistTaskToCheck?.checklistEntryStatus === statusToCheck
    }

    function reloadActiveChecklist(activeTabId: number) {
      activeTab.value = activeTabId

      isFilterDropdownOpen.value = false

      init()
    }

    function excludeHorizontalHeader(headerText: string): boolean {
      const excludedHeaders = [
        root.$t('persons.checklists.table.col.title.id'),
        root.$t('persons.checklists.table.col.title.person'),
        root.$t('persons.checklists.table.col.title.contractStartEnd'),
        root.$t('persons.checklists.table.col.title.supplier'),
        root.$t('persons.checklists.table.col.title.organizationalUnit'),
        root.$t('persons.checklists.table.col.title.activeProfessionalUnits'),
        root.$t('persons.checklists.table.col.title.countNotes'),
      ]

      return !excludedHeaders.includes(headerText)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onDblClickRow([_, { item: checklist }]: [void, { item: ChecklistOutput }]): void {
      const person = personsBasic.value?.find((person) => person.id === checklist.person.id)

      root.$router.push({
        name: 'person-profile',
        params: { id: String(person?.id) },
      })
    }

    const { getPerson, data: person } = useGetPerson()

    const isNotesDialogOpen = ref<boolean>(false)

    const personNotes = computed(() => person.value?.notes ?? [])

    async function onOpenNotesDialog(personId: number): Promise<void> {
      isNotesDialogOpen.value = true

      activePersonId.value = personId

      await getPerson(Number(activePersonId.value))
    }

    async function onReloadNotes(): Promise<void> {
      await getPerson(Number(activePersonId.value))
    }

    function onCloseNotesDialog(): void {
      isNotesDialogOpen.value = false

      debouncedCb.value()
    }

    return reactive({
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiFilter,
        mdiNotebookEditOutline,
      },
      constants: {
        CHECKLIST_ENTRY_STATUS,
        TABLE_HEADERS,
        ENTRY_HEADERS,
        Rights,
        FILTER_FIELDS,
      },
      state: {
        checklists,
        totalChecklistsCount,
        checklistTemplates,
        isLoadingChecklists,
        isFilterDropdownOpen,
        vuetifyTableOptions,

        isNotesDialogOpen,

        personNotes,
        activePersonId,
      },
      listeners: {
        onChangeChecklistTaskStatus,

        onToggleFilterDropdown,

        onDblClickRow,

        onReloadNotes,
        onOpenNotesDialog,
        onCloseNotesDialog,
      },
      functions: {
        debouncedCb,
        hasSufficientRights,
        reloadActiveChecklist,
        dateDashNotationToDot,
        excludeHorizontalHeader,
        hasStatus,
      },
    })
  },
})
