import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  ChecklistTemplateId,
  ChecklistTemplateInput,
  ChecklistTemplateOutput,
  UseCreateChecklistTemplate,
  UseDeleteChecklistTemplate,
  UseGetChecklistTemplate,
  UseGetChecklistTemplates,
  UseGetChecklistTemplatesBasic,
  UseUpdateChecklistTemplate,
} from './types/checklistTemplate'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'checklist-templates'

function useGetChecklistTemplates(): UseGetChecklistTemplates {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetChecklistTemplates['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetChecklistTemplatesBasic(): UseGetChecklistTemplatesBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useGetChecklistTemplate(): UseGetChecklistTemplate {
  const axios = useAxios<PromiseType<ReturnType<UseGetChecklistTemplate['getChecklistTemplate']>>>({ method: 'GET' })

  function getChecklistTemplate(id: ChecklistTemplateId): Promise<ChecklistTemplateOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getChecklistTemplate,
  }
}

function useCreateChecklistTemplate(): UseCreateChecklistTemplate {
  const axios = useAxios<PromiseType<ReturnType<UseCreateChecklistTemplate['createChecklistTemplate']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createChecklistTemplate(data: ChecklistTemplateInput): Promise<ChecklistTemplateOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createChecklistTemplate,
  }
}

function useUpdateChecklistTemplate(): UseUpdateChecklistTemplate {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateChecklistTemplate['updateChecklistTemplate']>>>({
    method: 'PUT',
  })

  function updateChecklistTemplate(
    id: ChecklistTemplateId,
    data: ChecklistTemplateOutput
  ): Promise<ChecklistTemplateOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateChecklistTemplate,
  }
}

function useDeleteChecklistTemplate(): UseDeleteChecklistTemplate {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteChecklistTemplate['deleteChecklistTemplate']>>>({
    method: 'DELETE',
  })

  function deleteChecklistTemplate(id: ChecklistTemplateId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteChecklistTemplate,
  }
}

export {
  useGetChecklistTemplates,
  useGetChecklistTemplatesBasic,
  useGetChecklistTemplate,
  useCreateChecklistTemplate,
  useUpdateChecklistTemplate,
  useDeleteChecklistTemplate,
}
