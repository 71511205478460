enum FILTER_FIELD_KEY {
  PersonIds = 'personIds',
  ProfessionalUnitIds = 'professionalUnitIds',
  ProfessionalUnitIdsWithChildren = 'professionalUnitIdsWithChildren',
  OrganizationalUnitIds = 'organizationalUnitIds',
  OrganizationalUnitIdsWithChildren = 'organizationalUnitIdsWithChildren',
  SupplierIds = 'supplierIds',
  ContractStartFrom = 'contractStartFrom',
  ContractStartTo = 'contractStartTo',
  ContractEndFrom = 'contractEndFrom',
  ContractEndTo = 'contractEndTo',
  IncludeClosed = 'includeClosed',
}

export { FILTER_FIELD_KEY }
